import React from "react"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import homePageStyle from "../../styles/homepage.module.scss"
import testinomialsStyle from "./testinomials.module.scss"
import Slider from "react-slick"
import { ReactComponent as RedArrow } from "../../styles/icons/arrow-red.svg"
import testimonials from "../../data/testimonials"

export default function (props) {
  const { wave, waveM } = useStaticQuery(graphql`
    query testinomialWave {
      wave: file(relativePath: { eq: "HomepageWave.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      waveM: file(relativePath: { eq: "m-testimonial.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
          fixed(height: 700) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  const quotes = testimonials.content.section2.quotes
  const data = [
    {
      quote: quotes[0].p[0],
      author: quotes[0].author,
    },
    {
      quote: quotes[1].p[1],
      author: quotes[1].author,
    },
  ]
  return (
    <section className={testinomialsStyle.wrapper}>
      {/* <div className={testinomialsStyle.testinomials}> */}
      <div>
        <Img
          className={testinomialsStyle.backgroundImage}
          fluid={wave.childImageSharp.fluid}
        ></Img>
        <Img
          className={testinomialsStyle.backgroundImageM}
          fluid={waveM.childImageSharp.fluid}
        ></Img>
      </div>
      <div className={testinomialsStyle.slider}>
        <Slider
          {...{
            arrows: true,
            centerMode: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: true,
            autoplay: false,
            speed: 500,
            autoplaySpeed: 30000,
            adaptiveHeight: false,
          }}
        >
          {data.map((testinomial, index) => (
            <div className={testinomialsStyle.testinomial} key={index}>
              <div className={testinomialsStyle.text}>
                <p className={testinomialsStyle.quote}>
                  <span className={testinomialsStyle.doublequote}>"</span>
                  {testinomial.quote}
                </p>
                <p className={testinomialsStyle.author}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "-" + testinomial.author,
                    }}
                  ></span>
                  &nbsp;
                  <Link to="/about/testimonials" className="primary white">
                    See More <RedArrow></RedArrow>
                  </Link>
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
