import React from "react";
import './carousel.scss';
import Carousel from 'react-bootstrap/Carousel';
import Img from "gatsby-image";
import { Link, StaticQuery, graphql } from 'gatsby';
import navItems from '../../data/navItems'

export default function (props) {
    // console.log(props);
    return (
        <StaticQuery
            query={graphql`query sliderImages {
                slider1: file(relativePath: {eq: "slider1.png"}) {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                        fixed(width: 200) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
                slider2: file(relativePath: {eq: "slider2.png"}) {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                        fixed(width: 200) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
                slider3: file(relativePath: {eq: "slider3.png"}) {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                        fixed(width: 200) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }`}
            render={data => {
                return (
                  <Carousel
                    nextLabel=""
                    prevLabel=""
                    controls={false}
                    interval={10000}
                  >
                    <Carousel.Item key={3}>
                      <Img fluid={data.slider3.childImageSharp.fluid} />
                      <Carousel.Caption className="homepage-carousel-caption">
                        <h1>
                          <span className="tail" style={{ color: "#3c3c3c" }}>
                            We Clean the Air You Breathe.
                          </span>
                        </h1>
                        <p style={{ color: "#3c3c3c" }}>
                          We’ve never accepted industry standards as ‘good
                          enough,’ and neither should you.
                        </p>
                        <Link to="/products" className="primary red">
                          Elevate Your Work Environment
                        </Link>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item key={1}>
                      <Img fluid={data.slider1.childImageSharp.fluid} />
                      <Carousel.Caption className="homepage-carousel-caption white">
                        <h1>
                          <span className="tail">Quality Tested.</span>
                        </h1>
                        <p>
                          Every product is built and tested right–every
                          time–because keeping you safe is our mission.
                        </p>
                        <Link to={navItems[1].to} className="primary white">
                          See The Clean Air Filter Standard
                        </Link>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item key={2}>
                      <Img fluid={data.slider2.childImageSharp.fluid} />
                      <Carousel.Caption className="homepage-carousel-caption white">
                        <h1>
                          <span className="tail">Performance Proven.</span>
                        </h1>
                        <p>
                          We hold ourselves and our products to the most
                          rigorous standards to ensure a clean, safe working
                          environment for people who are exposed to
                          contaminants.
                        </p>
                        <Link to={navItems[1].to} className="primary red">
                          See The Clean Air Filter Standard
                        </Link>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                )
            }}
        />
    );
}

