import React, { useEffect } from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

export default function () {
    const { parallaxTop, parallaxBottom } = useStaticQuery(graphql`
    query parallaxImages {
        parallaxTop: file(relativePath: {eq: "Parallax-1.png"}) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        parallaxBottom: file(relativePath: {eq: "Parallax-2.png"}) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }`);

    return <div
        style={{
            background: '#fcfcfc',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%'
        }}
    >
        <Img fluid={parallaxTop.childImageSharp.fluid} alt="background wave"></Img>
        <Img fluid={parallaxBottom.childImageSharp.fluid} alt="background wave"></Img>
    </div>;
}

