import React, { useEffect } from "react";
import Carousel from '../components/carousel';
import CallOut from '../components/callOut';
import Layout from "../components/layout";
import homePageStyle from '../styles/homepage.module.scss';
import Display from '../components/display';
import CafStatement from '../components/sharedResources/Footer-Logo2.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import Parallax from '../components/parallax';
import Testinomials from '../components/testinomials';
import testinomials from '../data/testinomials';

export default function Home(props) {
  useEffect(() => {
    window.addEventListener('scroll', (event) => {
    });

    return () => {
      window.removeEventListener('scroll', () => { });
    };
  });
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clean Air Filter</title>
      </Helmet>
      <Carousel></Carousel>
      <Parallax></Parallax>
      {/* <CallOut></CallOut> */}
      <section className={homePageStyle.intro}>
        <h1
          style={{
            margin: "auto",
          }}
        >
          <span className="tail">
            <span className="head">Setting the Standard for</span> Clean Air.
          </span>
        </h1>
        <p>
          At Clean Air Filter, CAF, we’re committed to doing more than the
          minimum, because we firmly believe that the future is made by what we
          do today. For over three decades, we’ve dedicated ourselves to
          building products that protect you today to ensure a healthier
          tomorrow. We hold ourselves to a higher standard and refuse to settle
          for anything less than exceptional. We will effectively ‘check all of
          the boxes’ to design exceptional air filtration solutions that you can
          depend on to keep you safe and healthy for years to come.
        </p>
        <img
          className={homePageStyle.cafStatement}
          src={CafStatement}
          alt="caf logo"
        ></img>
      </section>
      <Display></Display>
      <Testinomials testinomials={testinomials}></Testinomials>
    </Layout>
  )
}
